import { enableProdMode } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getLocale, setLocale } from '@shared/locale-helpers';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// get current locale and store it in the session storage
// order of locale determination: url, session storage, browser languages, default language
const locale = getLocale();
setLocale(locale);

const localeFileName = locale === 'en-US' ? 'messages.json' : `messages.${locale}.json`;

// fetch the translation file we need for runtime translations. This could also point to an API endpoint
fetch(`/assets/i18n/${localeFileName}`)
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    return response.json();
  })
  .then(async(result) => {
    // load the selected language file
    loadTranslations(result.translations);

    // we have localize strings in CONFIG file. So only import the app module after the translations are loaded
    import('./app/app.module').then((mod) => {
      const AppModule = mod.AppModule;
      platformBrowserDynamic()
        .bootstrapModule(AppModule)
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
    });
  });
